<template>
  <div>
    <p class="subtitle">消息列表</p>
    <el-card v-for="(item, i) in tableData" :key="i" shadow="hover">
      <template>
        <span v-if="item.status === 'PASS'" class="green">「通过」</span>
        <span v-if="item.status === 'FAIL'" class="red">「准入不通过」</span>
      </template>
      <router-link :to="{ name: 'MerchantList', query: { merchant_no: item.draft_merchants_no } }">
        <!-- 商户名（未读的消息:有个小点,已读的消息:没有小点） -->
        <el-badge is-dot v-if="item.read_status === '0'">{{ item.short_name }} </el-badge>
        <span v-else>{{ item.short_name }}</span>
      </router-link>
      <span class="time">
        <span class="blue pointer" v-if="item.read_status === '0'" @click="messageRead(item.no, item.message)">
          点我查看</span
        >
        <span>{{ item.created }}</span>
      </span>
    </el-card>
    <Pagination :total="total" @init="init" ref="pagination" />
  </div>
</template>

<script>
import { messageList, messageRead } from '@/api/api'
import Pagination from '@/components/pagination'
import { MessageBox } from 'element-ui'

export default {
  name: 'News', //消息
  components: { Pagination },
  data() {
    return {
      tableData: [],
      total: 1
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const pagination = this.$refs.pagination
      const params = {
        page: pagination.page,
        limit: pagination.limit
      }
      messageList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.total = res.data.count
        }
      })
    },
    // 读消息
    messageRead(no, message) {
      MessageBox.confirm(message, {
        confirmButtonText: '读取',
        cancelButtonText: '取消'
      })
        .then(() => {
          messageRead({ no }).then((res) => {
            if (res.code === 200) {
              this.init()
            }
          })
        })
        .catch(() => {
          return false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-card {
  margin-bottom: 10px;
  .time {
    float: right;
    .blue {
      margin-right: 10px;
    }
  }
}
</style>

